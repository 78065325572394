<template>
  <div class="gallery-page">
    <section class="section-contact-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-old shape-skew">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card
          shadow
          class="card-contact mt--400"
          no-body>
            <div class="gallery">
			        <h1>Mijn realisaties</h1>
              <div class="stacks">
                <div class="stack" v-for="(stack, index) in stacks" v-bind:key="index" v-on:click="navigateGallery(stack.id)">
                  <div>
                    <img :src="stack.src" :alt="stack.title" />
                  </div>
                </div>
              </div>
            </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>

import router from "../router.js";

export default {
  data() {
    return {
      stacks: [
        {
          id: '1',
          title: 'Realisatie 1',
          src: 'img/realisaties/1/realisaties_1.png'
        },
        {
          id: '2',
          title: 'Realisatie 2',
          src: 'img/realisaties/2/realisaties_1.jpg'
        },
        {
          id: '3',
          title: 'Realisatie 3',
          src: 'img/realisaties/3/realisaties_1.jpg'
        },
        {
          id: '4',
          title: 'Realisatie 4',
          src: 'img/realisaties/4/realisaties_1.jpg'
        },
        {
          id: '5',
          title: 'Realisatie 5',
          src: 'img/realisaties/5/realisaties_1.jpg'
        },
        {
          id: '6',
          title: 'Realisatie 6',
          src: 'img/realisaties/6/realisaties_1.jpg'
        },
        {
          id: '7',
          title: 'Realisatie 7',
          src: 'img/realisaties/7/realisaties_1.jpg'
        },
        {
          id: '8',
          title: 'Realisatie 8',
          src: 'img/realisaties/8/realisaties_1.jpg'
        },
        {
          id: '9',
          title: 'Realisatie 9',
          src: 'img/realisaties/9/realisaties_1.jpg'
        }
      ]
    };
  },
  methods: {
    navigateGallery: function(id) {
      router.push({ name: 'detail', params: { id: id } })
    }
  }
};
</script>
<style lang="scss" scoped>
.gallery {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  h1 {
    margin-left: 55px;
  }
  @media screen and (max-width: 750px) {
    h1 {
		  font-size: 2em;
    }
	}
}

.stacks {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.stack {
  margin: 15px;
  width: 200px;
  height: 200px;
  position: relative; 
  margin-bottom: 20px; 
  display: inline-block;
  background: #fff;
	padding: 10px;
  -moz-box-shadow:    1px 1px 2px rgba(0,0,0,.25);
  -webkit-box-shadow: 1px 1px 2px rgba(0,0,0,.25);
  box-shadow:         1px 1px 2px rgba(0,0,0,.25);
  &:hover {
    cursor: pointer;
  }
  div{
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
        height: 100%;
        transform: translateX(-15%);
        transition: transform 0.2s ease-in;
        &:hover {
          transform: translateX(-15%) rotate(3deg) scale(1.2);
        }
    }
  }
}

.stack:before { 
	background: #f3f3f3;
	content: ""; 
	width: 100%; 
	height: 100%; 
	position: absolute; 
	left: 0;
  top: 0; z-index: -10;
  transform: rotate(-6deg);
  -webkit-transform: rotate(-6deg);
  -moz-transform: rotate(-6deg);
  -ms-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  -moz-box-shadow:    1px 1px 2px rgba(0,0,0,.25);
  -webkit-box-shadow: 1px 1px 2px rgba(0,0,0,.25);
  box-shadow:         1px 1px 2px rgba(0,0,0,.25);
  transition: transform 0.2s ease-out;
  &:hover {
    transform: rotate(-8deg);
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    -ms-transform: rotate(-8deg);
    -o-transform: rotate(-8deg);
  }
}
.stack:hover:before { 
    transform: rotate(-13deg);
    -webkit-transform: rotate(-13deg);
    -moz-transform: rotate(-13deg);
    -ms-transform: rotate(-13deg);
    -o-transform: rotate(-13deg);
}
.stack:hover:after {
    transform: rotate(-8deg);
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    -ms-transform: rotate(-8deg);
    -o-transform: rotate(-8deg);
  }
.stack:after { 
	background: #f3f3f3;
	content: ""; 
	width: 100%; 
	height: 100%; 
	position: absolute; 
	left: 0;
  top: 0; z-index: -9;
  transform: rotate(-4deg);
  -webkit-transform: rotate(-4deg);
  -moz-transform: rotate(-4deg);
  -ms-transform: rotate(-4deg);
  -o-transform: rotate(-4deg);
  -moz-box-shadow:    1px 1px 2px rgba(0,0,0,.25);
  -webkit-box-shadow: 1px 1px 2px rgba(0,0,0,.25);
  box-shadow:         1px 1px 2px rgba(0,0,0,.25);
  transition: transform 0.2s ease-out;
  &:hover {
    transform: rotate(-6deg);
    -webkit-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);
    -ms-transform: rotate(-6deg);
    -o-transform: rotate(-6deg);
  }
}
</style>
