<template>
    <section class="section section-lg section-shaped overflow-hidden my-0" id="werkplaats">
        <div class="container py-0 pb-lg">
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-5 mb-5 mb-lg-0">
                    <h1 class="font-weight-light text-white">Onze werkplaats</h1>
                    <p class="lead mt-4">Onze werkplaats is gelegen te Lochristi. Kom gerust even langs!</p>
                    <div class="btn-wrapper">
                                    <base-button tag="a"
                                                 href="#/contact"
                                                 class="mb-3 mb-sm-0"
                                                 type="yellow"
                                                 icon="ni ni-pin-3">
                                        Locatie
                                    </base-button>
                                </div>
                </div>
                <div class="col-lg-6 mb-lg-auto">
                    <div class="rounded shadow-lg overflow-hidden">
                        <b-carousel id="carousel1"
                                    controls
                                    indicators>
                            <b-carousel-slide v-for="(image, index) in images" v-bind:key="index" :img-src="image"></b-carousel-slide>
                        </b-carousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import BCarousel from "bootstrap-vue/es/components/carousel/carousel";
import BCarouselSlide from "bootstrap-vue/es/components/carousel/carousel-slide";

export default {
  components: {
    BCarousel,
    BCarouselSlide
  },
  data: () => {
      return {
          images: [
              'img/werkplaats/werkplaats_2.png',
              'img/werkplaats/werkplaats_3.png',
              'img/werkplaats/werkplaats_4.png',
              'img/werkplaats/werkplaats_5.png',
              'img/werkplaats/werkplaats_6.png'
          ]
      }
  }
};
</script>
<style>
</style>
