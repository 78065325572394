<template>
  <div class="version-page">
    <section class="section-contact-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-old shape-skew">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card
          shadow
          class="card-contact mt--400"
          no-body>
        <div class="version">
          Huidige versie: {{version}}
         </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  
  computed: {
    version()  {
      return process.env.PACKAGE_VERSION || '0'
    }
  }
};
</script>
<style lang="scss" scoped>
.version {
  margin: auto;
}
</style>
