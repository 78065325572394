<template>
  <div class="workflow-page">
    <section class="section-contact-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-old shape-skew">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card
          shadow
          class="card-contact mt--400"
          no-body
        >
			<h1>Hoe gaan wij te werk?</h1>
          <div class="timeline-section">
            <ul
              class="vertical-timeline"
              id="timeline-scroll">
              <li v-for="(item, index) in workflow"
                  v-bind:key="index">
                <div class="icon-container">
                  <span class="icon-ribbon-alt">{{item.step}}</span>
                </div>
                <div class="info" v-html="item.description">
                </div>
              </li>
            </ul>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
		workflow: [
			{
				step: '1',
				description: 'U neemt contact op met ons. U kan gerust langskomen of even bellen. Onze gegevens vind u <a href="#/contact">hier</a>.'
			},
			{
				step: '2',
				description: 'Wij maken voor u een offerte. Wij zorgen voor een goede prijs en zijn 100% eerlijk zodat u geen onvoorziene kost moet betalen.'
			},
			{
				step: '3',
				description: 'Wij demonteren de wagen en bestellen de onderdelen. Bij onvoorziene kosten laten wij dit steeds weten, zodat u op de hoogte bent voor wij verder gaan.'
			},
			{
				step: '4',
				description: `Na herstelling van de schade, bereiden wij het spuiten van de wagen voor. 
				<br/>Hierbij zorgen wij dat we het kleur zo accuraat als mogelijk overeen laten komen met die van uw wagen.
				<br/><br/>Dit doen wij met Nexa Color Spectrum Meter. 
				<br/> Deze elektronische kleurenzoeker geeft snel de meest nauwkeurige kleurovereenkomst.`
			},
			{
				step: '5',
				description: `Na het spuiten van de wagen wordt deze weer gemonteerd. 
				<br/>Hierbij wordt nog een laatste check gedaan van de herstelling, zodat we zeker zijn dat alles perfect is.`
			},
			{
				step: '6',
				description: 'De wagen is klaar en u kan deze terug ophalen in onze garage. '
			}
		]
	};
  },
  methods: {}
};
</script>
<style lang="scss" scoped>

h1 {
	margin-left: 135px;
	margin-top: 30px;

	@media screen and (max-width: 750px) {
		margin-left: 55px;
		font-size: 2em;
	}
}

.timeline-section {
	width: 80%;
}

ul.vertical-timeline {
  padding: 0;
  margin-left: 135px;
  padding-top: 20px;

  @media screen and (max-width: 750px) {
		margin-left: 55px;
	}

  li:last-child {
	  border-left: none;

	  .icon-container::before {
			content: "";
			position: absolute;
			height: 16px;
			border-left: 2px solid #eaeaea;
			width: 10px;
			transform: translate(18.5px, -17px);
	  }
  }

  li {
    list-style-type: none;
    padding: 0.9em;
    margin: 0;
    transition: background-color 0.5s;
    overflow: visible;
    border-left: 2px solid #eaeaea;

    .icon-container {
      float: left;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border: 2px solid #eaeaea;
      border-radius: 50%;
      margin-left: -35px;
      background: #f7f8fa;

	  .icon-ribbon-alt {
		  display: flex;
		  justify-content: center;
		  align-content: center;
		  font-size: 1.3em;
	  }
    }
    .info {
      text-align: left;
      background: #f7f8fa;
      margin-left: 15px;
      padding: 10px;
      font-size: 14px;
	  border-radius: 10px;
    }
  }
}
</style>
