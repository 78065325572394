import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Shared from "./plugins/shared";
import './registerServiceWorker'

Vue.config.productionTip = false;
Vue.use(Shared);
 

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
