<template>
  <div class="gallery-page">
    <section class="section-contact-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-old shape-skew">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card
          shadow
          class="card-contact mt--400"
          no-body
        >
          <div class="gallery-detail">
            <div
              v-for="(imgUrl, index) in images"
              v-bind:key="index"
              class="image-container"
            >
              <img
                :src="imgUrl"
                :alt="`Realisatie_${index}`"
                class="image"
              >
            </div>
          </div>
          <a
            href="javascript:history.back()"
            class="previous"
          >Terug naar overzicht</a>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
import router from "../router.js";

export default {
  /**
   * itemCount:
   *  - id: name of the subfolder
   *  - count: amount of items in the folder (for looping purposes)
   *  - format: format of the images (for looping purposes)
   */
  data() {
    return {
      id: "",
      directory: "img/realisaties/",
      filePrefix: "realisaties_",
      itemCount: [
        {
          id: "1",
          count: 5,
          format: "png"
        },
        {
          id: "2",
          count: 3,
          format: "jpg"
        },
        {
          id: "3",
          count: 5,
          format: "jpg"
        },
        {
          id: "4",
          count: 25,
          format: "jpg"
        },
        {
          id: "5",
          count: 6,
          format: "jpg"
        },
        {
          id: "6",
          count: 14,
          format: "jpg"
        },
        {
          id: "7",
          count: 21,
          format: "jpg"
        },
        {
          id: "8",
          count: 3,
          format: "jpg"
        },
        {
          id: "9",
          count: 1,
          format: "jpg"
        }
      ],
      images: []
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.loadImages(this.id);
  },
  methods: {
    loadImages: function(id) {
      const itemDetails = this.itemCount.find(item => item.id === id);
      for (let i = 1; i <= itemDetails.count; i++) {
        this.images.push(
          `${this.directory}${id}/${this.filePrefix}${i}.${itemDetails.format}`
        );
      }
    }
  }
};
</script>
<style lang="scss" scoped>
a.previous {
  font-size: 1.2em;
  margin: 10px auto;
  display: inline-block;
  padding:0.35em 1.2em;
  border:0.1em solid #032033;
  border-radius:0.32em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-weight:300;
  color: black;
  text-align:center;
  transition: all 0.2s;
}
a.previous:hover{
 color:#ffffff;
 background-color:#032033;
}
@media all and (max-width:30em){
 a.butpreviouston1{
  display:block;
  margin:0.4em auto;
 }
}
.gallery-detail {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  justify-content: center;
  .image-container {
    width: 29%;
    margin: 12px;
    .image {
      width: 100%;
      border-radius: 6px;
      box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }
}

@media screen and (max-width: 768px) {
  .gallery-detail {
    padding: 30px 0;
    .image-container {
      width: 49%;
    }
  }
}

@media screen and (max-width: 768px) {
  .gallery-detail {
    .image-container {
      width: 80%;
    }
  }
}
</style>
