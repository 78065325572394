<template>
    <div class="landing">

        <div class="position-relative">
                <img src="img/car.png" alt="car" class="landing-image">
            <section class="section-shaped my-0">
                <div class="shape shape-old shape-style-1 shape-default shape-skew border-bottom--yellow">
                </div>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="display-3  text-white">Carrosserie steendam
                                </h1>
                                <p class="lead  text-white">Carrosserie Steendam is gespecialiseerd in koetswerk herstellingen van alle merken autos, 
                                    alsook alle andere lakwerken voor zowel de particulier als professionelen.</p>
                                <div class="btn-wrapper">
                                    <base-button tag="a"
                                                 href="#/contact"
                                                 class="mb-3 mb-sm-0"
                                                 type="yellow"
                                                 icon="ni ni-pin-3">
                                        Openingsuren
                                    </base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section class="section section-lg pt-lg-0 mt--200">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <img src="/img/wrench.png" alt="wrench" class="card-img card-img--bottom-right">
                                    <h6 class="text-success text-uppercase">Aanbod</h6>
                                    <p class="description mt-3">
                                        Wij werken voor zowel particulieren als bedrijven. Wij zijn gespecialiseerd in:
                                        <ul>
                                            <li>Koetswerk herstellingen</li>
                                            <li>Volledige herschildering</li>
                                        </ul>
                                        <br>
                                        U kan bij ons terecht voor alle soorten wagens, zowel nieuwe als classic cars en lichte bestelwagens, 
                                        maar ook <b> oldtimers </b> en <b> sportwagens</b>  zijn welkom. 
                                    </p>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <img src="/img/rims.png" alt="rims" class="card-img card-img--top-right">
                                    <h6 class="text-warning text-uppercase">Onze troeven</h6>
                                    <p class="description mt-3">
                                        <ul>
                                            <li>Persoonlijke aanpak</li>
                                            <li>Kwaliteit
                                                <ul>
                                                    <li>Perfectionisme zit bij ons in het bloed</li>
                                                </ul>
                                            </li>
                                            <li>Afspraak is afspraak
                                                <ul>
                                                    <li>Alles wordt op voorhand afgesproken, zo zijn er geen onvoorziene kosten

                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </p>
                                    <base-button tag="a" href="#/contact" type="warning" class="mt-4 card-button">
                                        Maak een afspraak
                                    </base-button>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    
                                    <h6 class="text-primary text-uppercase">Schade! Wat nu?</h6>
                                    <p class="description mt-3">
                                        Eerst en vooral, geen paniek! Wij zorgen voor een vakkundig herstel.
                                        <br>
                                        <br>
                                        Bent u niet zeker wat u nu moet doen of wilt u gewoon weten hoe wij te werk gaan? 
                                        <br>
                                        Bekijk hier wat de volgende stappen bij uw herstelling zijn.
                                        <br><br>
                                        <br>
                                    </p>
                                    <base-button tag="a" href="#/workflow" type="primary" class="mt-4 card-button">
                                        Meer info
                                    </base-button>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-werkplaats">
            <Werkplaats></Werkplaats>
        </section>

    </div>
</template>

<script>
import Werkplaats from './components/Werkplaats'
export default {
  name: "home",
  components: {
      Werkplaats
  }
};
</script>

<style lang="scss" scoped>
.landing {
    user-select: none;
}
.section-werkplaats {
    background-color: #032033;
    color: white;
}
.landing-image {
    width: 500px;
    position: absolute;
    left: 57%;
    top: 30%;
}

.card-img {
    position: absolute;
    right: 0;
    opacity: 0.3;
    &.card-img--top-right {
        top: 0;
        width: 250px;
        transform: translateY(-38%) translateX(38%);
    }

    &.card-img--bottom-right {
        bottom: 0;
        transform: rotateX(180deg) translateY(-70px) translateX(49px);
        width: 175px;
    }
}
</style>
