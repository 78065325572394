import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Landing from "./views/Landing.vue";
import Contact from "./views/Contact.vue";
import Workflow from "./views/Workflow.vue";
import Gallery from "./views/Gallery.vue";
import GalleryDetail from "./views/GalleryDetail.vue";
import Version from "./views/Version.vue";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "components",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter
      }
    },
    {
      path: "*", // vang alles op en redirect naar home
      name: "home",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter
      }
    },
    {
      path: "/landing",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter
      }
    },
    {
      path: "/contact",
      name: "contact",
      components: {
        header: AppHeader,
        default: Contact,
        footer: AppFooter
      }
    },
    {
      path: "/workflow",
      name: "workflow",
      components: {
        header: AppHeader,
        default: Workflow,
        footer: AppFooter
      }
    },
    {
      path: "/realisaties",
      name: "realisaties",
      components: {
        header: AppHeader,
        default: Gallery,
        footer: AppFooter
      }
    },
    {
      path: "/detail/:id",
      name: "detail",
      components: {
        header: AppHeader,
        default: GalleryDetail,
        footer: AppFooter
      }
    },
    {
      path: "/version",
      name: "version",
      components: {
        header: AppHeader,
        default: Version,
        footer: AppFooter
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ selector: to.hash })
        }, 500)
      })
    } else {
      return { x: 0, y: 0 };
    }
  }
});
