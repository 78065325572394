<template>
  <div class="contact-page">
    <section class="section-contact-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-old shape-skew">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card
          shadow
          class="card-contact mt--400"
          no-body
        >
          <div>
            <div class="contact-container">
              <div class="text-center mt-5 col-lg-6 flex contact-location">
                <div>
                  <h3>
                    CARROSSERIE STEENDAM
                  </h3>
                  <div class="h6 font-weight-300">
                    <i
                      class="ni ni-square-pin mr-2"
                      style="transform: translateY(10px);"
                    ></i>
                    <span>
                      Ambachtenlaan 2K
                      <br>
                      9080 Lochristi
                    </span>
                  </div>
                  <div class="h6 font-weight-300">
                    <i class="fa fa-mobile mr-2"></i>
                    <a href="tel:0479 79 65 93">0479 79 65 93</a>
                  </div>
                  <div class="h6 font-weight-300">
                    <i class="ni ni-email-83 mr-2"></i>
                    <a href="mailto:carrosserie.steendam@telenet.be">carrosserie.steendam@telenet.be</a>
                  </div>
                  <p>Ondernemersnummer: BE 0647 463 518</p>
                </div>
                <div class="maps-container mt-4">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1252.5772432099618!2d3.870893876865452!3d51.10560979571589!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c39d379eafa2d7%3A0xf079ba9878a1556d!2sCARROSSERIE%20STEENDAM!5e0!3m2!1snl!2sbe!4v1567969345497!5m2!1snl!2sbe"
                    frameborder="0"
                    class="maps-iframe"
                    allowfullscreen=""
                  ></iframe>
                </div>
              </div>
              <div class="text-center mt-5 col-lg-6 contact-opening">
                <h3>Openingsuren</h3>
                <h5 :class="isNowOpen() ? 'open' : 'closed'">Nu {{isNowOpen() ? 'OPEN' : 'GESLOTEN'}}</h5>
                <div
                  v-for="(day, index) in days"
                  v-bind:key="index"
                  class="day-row"
                  :class="currentDayClass(day.index)">
                  <div class="col-lg-3">
                    <b> {{day.text}} </b>
                  </div>
                  <div class="col-lg-4 contact-opening-hours">{{day.morning}} <br> {{day.afternoon}}</div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentDay: true,
      error: null,
      days: [
        {
          index: 1,
          text: "Maandag",
          morning: "08u00 – 12u00",
          afternoon: "13u00 - 18u00"
        },
        {
          index: 2,
          text: "Dinsdag",
          morning: "08u00 – 12u00",
          afternoon: "13u00 - 18u00"
        },
        {
          index: 3,
          text: "Woensdag",
          morning: "08u00 – 12u00",
          afternoon: "13u00 - 18u00"
        },
        {
          index: 4,
          text: "Donderdag",
          morning: "08u00 – 12u00",
          afternoon: "13u00 - 18u00"
        },
        {
          index: 5,
          text: "Vrijdag",
          morning: "08u00 – 12u00",
          afternoon: "13u00 - 18u00"
        },
        {
          index: 6,
          text: "Zaterdag",
          morning: "Gesloten",
          afternoon: ""
        },
        {
          index: 0,
          text: "Zondag",
          morning: "Gesloten",
          afternoon: ""
        }
      ]
    };
  },
  methods: {
    currentDayClass: function(day) {
      const activeDayString = 'active-day';
      if (this.isToday(day)) {        
        return this.isNowOpen() ? `${activeDayString} open` : activeDayString;
      }
    },
    isToday: (day) => {
      return day === new Date().getDay()
    },
    isNowOpen: function() {
      const currentDay = this.getDayFromList(this.days, new Date().getDay());
      const {openingHour, closingHour, now} = this.getHours(currentDay.morning, currentDay.afternoon);
      return now >= openingHour && now < closingHour;
    },
    getDayFromList: (list, dayIndex) => {
      return list.filter((day) => day.index === dayIndex)[0]
    },
    getHours: function(morning, afternoon) {
      return {
        openingHour: this.getHourFromString(morning, 0),
        closingHour: this.getHourFromString(afternoon, 6),
        now: new Date().getHours()
      }
    },
    getHourFromString: (string, startIndex) => {
      const substring = string.replace(/\s/g,'').substring(startIndex, startIndex + 2);
      return parseInt(substring);
    }
  }
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
}

.contact-container {
  display: flex;

  @media screen and (max-width: 1000px) {
    display: block;
  }
}

.contact-location {
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1000px) {
    justify-content: start;
    padding: 0;
  }

  .maps-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 20px;

    .maps-iframe {
      width: 420px;
      height: 400px;
      border: 1px solid #b7b7b7;
      border-radius: 7px;

      @media screen and (max-width: 768px) {
        width: 300px;
        height: 350px;
      }
      @media screen and (max-width: 650px) {
        width: 200px;
        height: 250px;
      }
    }

    @media screen and (max-width: 1000px) {
      display: block;
    }
  }
}

.contact-opening {
  font-size: 0.9em;
  @media screen and (max-width: 360px) {
    .contact-opening-hours {
      font-size: 0.8em;
    }
  }
}

.day-row {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin: 5px 0;

  div {
    margin-right: 20px;
  }

  div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -3px;
    width: 10%;
    left: 63%;
    transform: translateX(-50%);
    border-bottom: 1px solid #b5b5b5;
  }

  &:last-child:after {
    border: none;
  }
}

.active-day {
  background-color: #ffef45c9;
  border-radius: 6px;
  color: red;
}

.open {
  color: green;
}
.closed {
  color: red;
}
</style>
