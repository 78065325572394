<template>
  <header
    class="header-global"
    id="header-global"
    :style="prevScrollPos >= currentScrollPos ? 'top: 0px' : 'top:-50px'">
    <base-nav
      class="navbar-main"
      transparent
      type=""
      expand
    >
      <router-link
        slot="brand"
        class="navbar-brand mr-lg-5"
        to="/"
      >
        Carrosserie steendam
      </router-link>

      <div
        class="row"
        slot="content-header"
        slot-scope="{closeMenu}"
      >
        <div class="col-10 collapse-brand">
          Carrosserie Steendam
        </div>
        <div class="col-1 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <a
          slot="title"
          href="#/"
          class="nav-link navbar-home"
          role="button"
          @click="closeMenu"
        >
          <i class="fa fa-home"></i>
          <span class="nav-link-inner--text">Home</span>
        </a>
        <a
          slot="title"
          href="#/#werkplaats"
          class="nav-link"
          role="button"
          @click="closeMenu"
        >
          <i class="ni ni-ui-04 d-lg-none"></i>
          <span class="nav-link-inner--text">Werkplaats</span>
        </a>
        <a
          slot="title"
          href="#/realisaties"
          class="nav-link"
          role="button"
          @click="closeMenu"
        >
          <i class="ni ni-ui-04 d-lg-none"></i>
          <span class="nav-link-inner--text">Realisaties</span>
        </a>
        <a
          slot="title"
          href="#/workflow"
          class="nav-link"
          role="button"
          @click="closeMenu"
        >
          <i class="ni ni-ui-04 d-lg-none"></i>
          <span class="nav-link-inner--text">Stappenplan</span>
        </a>
        <a
          slot="title"
          href="#/contact"
          class="nav-link"
          role="button"
          @click="closeMenu"
        >
          <i class="ni ni-collection d-lg-none"></i>
          <span class="nav-link-inner--text">Contacteer mij</span>
        </a>
      </ul>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto navbar-autocolor">
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://bena.nexaautocolor.com/nl/"
            target="_blank"
            rel="noopener"
            data-toggle="tooltip"
            title="Nexa autocolor"
          >
            <img
              src="/img/nexa-autocolor.svg"
              alt=""
            >
          </a>
        </li>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";
import { EventBus } from '@/eventBus.js';

export default {
  components: {
    BaseNav,
    CloseButton
  },
  data: () => {
    return {
        prevScrollPos: 0,
        currentScrollPos: 0
    }
  },

  methods: {
    updateScroll() {
      this.prevScrollPos = this.currentScrollPos;
      this.currentScrollPos = window.scrollY;
    },
    closeMenu() {
      EventBus.$emit('closeMenu');
    }
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  }
};
</script>
<style lang="scss" scoped>
.navbar-main::after {
  content: "";
  position: absolute;
  width: 80%;
  border-top: solid #fff100;
  top: 0;
  transform: translateX(-50%);
  left: 50%;
}
.navbar-home {
    display: none;
  }

@media screen and (max-width: 1000px) {
  .navbar-autocolor {
    display: none;
  }
  .navbar-home {
    i {
      font-size: 1.2em;
    }
    display: inline-block;
  }
}
</style>
